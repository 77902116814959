import axios from 'axios';

const headers = {
    'Content-Type': 'application/json'
};

export const getOrganizationsFromAPI = async () => {
    const apiUrl = `${process.env.VUE_APP_SERVER_BASE_URL}/data/sites`;
    return axios.get(apiUrl, { headers }).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
};

export const getDataFromAPI = async (queryParams) => {
    const apiUrl = `${process.env.VUE_APP_SERVER_BASE_URL}/data${queryParams}`;
    return axios.get(apiUrl, { headers }).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
};