var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-drawer"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-4",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mb-1 d-flex align-center"},[_c('v-col',[_c('span',{staticStyle:{"color":"#ffffff","font-size":"1.3rem"}},[_vm._v("Filter")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"color":"#2196F3","open-delay":"300","transition":"scale-transition","z-index":"12","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-n1",attrs:{"icon":""},on:{"click":_vm.clearFilter}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-autorenew")])],1)]}}])},[_c('span',[_vm._v("Clear Filters")])])],1)],1),_c('v-form',{ref:"filterForm",attrs:{"lazy-validation":""},on:{"submit":_vm.applyFilter},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"auto","transition":"scale-transition","offset-y":"","z-index":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || 'Date is required'; }],"label":"Date","append-icon":"mdi-calendar","outlined":"","hide-details":"","readonly":"","required":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"max":_vm.today,"color":"#2196F3"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-select',{staticClass:"mt-8",attrs:{"items":_vm.organizationsData,"menu-props":{ bottom: true, offsetY: true },"item-text":"fullName","item-value":"code","label":"Organizations","clearable":"","multiple":"","outlined":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',{attrs:{"color":"primary"}},[_vm._v(" Organizations: "+_vm._s(_vm.organizationsFilter.length)+" ")]):_vm._e()]}}]),model:{value:(_vm.organizationsFilter),callback:function ($$v) {_vm.organizationsFilter=$$v},expression:"organizationsFilter"}}),_c('v-select',{attrs:{"items":_vm.races,"menu-props":{ bottom: true, offsetY: true },"label":"Race","clearable":"","outlined":""},model:{value:(_vm.raceFilter),callback:function ($$v) {_vm.raceFilter=$$v},expression:"raceFilter"}}),_c('v-select',{attrs:{"items":_vm.genders,"menu-props":{ bottom: true, offsetY: true },"label":"Gender","clearable":"","outlined":""},model:{value:(_vm.genderFilter),callback:function ($$v) {_vm.genderFilter=$$v},expression:"genderFilter"}}),_c('v-select',{attrs:{"items":_vm.ages,"menu-props":{ bottom: true, offsetY: true },"label":"Age","clearable":"","outlined":""},model:{value:(_vm.ageFilter),callback:function ($$v) {_vm.ageFilter=$$v},expression:"ageFilter"}}),_c('v-btn',{attrs:{"color":"blue","block":""},on:{"click":_vm.applyFilter}},[_vm._v(" Apply ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }