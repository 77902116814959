<template>
  <div class="filter-drawer">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-4">
        <v-row class="mb-1 d-flex align-center">
          <v-col>
            <span style="color: #ffffff; font-size: 1.3rem">Filter</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-tooltip
              color="#2196F3"
              open-delay="300"
              transition="scale-transition"
              z-index="12"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clearFilter"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-n1"
                  icon
                >
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </template>
              <span>Clear Filters</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-form
          @submit="applyFilter"
          ref="filterForm"
          v-model="valid"
          lazy-validation
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            min-width="auto"
            transition="scale-transition"
            offset-y
            z-index="12"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'Date is required']"
                label="Date"
                append-icon="mdi-calendar"
                outlined
                hide-details
                readonly
                required
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu = false"
              :max="today"
              color="#2196F3"
            >
            </v-date-picker>
          </v-menu>
          <v-select
            v-model="organizationsFilter"
            :items="organizationsData"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="fullName"
            item-value="code"
            label="Organizations"
            class="mt-8"
            clearable
            multiple
            outlined
            small-chips
          >
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" color="primary">
                Organizations: {{ organizationsFilter.length }}
              </span>
            </template>
          </v-select>
          <v-select
            v-model="raceFilter"
            :items="races"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Race"
            clearable
            outlined
          ></v-select>
          <v-select
            v-model="genderFilter"
            :items="genders"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Gender"
            clearable
            outlined
          ></v-select>
          <v-select
            v-model="ageFilter"
            :items="ages"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Age"
            clearable
            outlined
          ></v-select>
          <v-btn @click="applyFilter" color="blue" block> Apply </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FilterDrawer',
  data: () => ({
    ages: [13, 14, 15, 16, 17, 18],
    genders: ['Male', 'Female', 'Other'],
    races: [
      'African-American or Black',
      'American Indian or Alaska Native',
      'Asian',
      'Hispanic or Latino',
      'Other',
      'White',
    ],
    ageFilter: '',
    genderFilter: '',
    organizationsFilter: '',
    raceFilter: '',
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    valid: true,
  }),
  computed: {
    ...mapGetters('organizations', {
      organizationsData: 'organizations',
    }),
    queryParams() {
      let params = '?date=' + this.date;
      if (this.organizationsFilter) {
        params += '&siteFilter=' + this.organizationsFilter;
      }
      return params;
    },
    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },
  methods: {
    ...mapActions('data', {
      getData: 'getData',
    }),
    applyFilter() {
      if (!this.$refs.filterForm.validate()) {
        return;
      }
      this.getData(this.queryParams);
    },
    clearFilter() {
      this.$refs.filterForm.reset();
      this.$nextTick(() => {
        this.date = this.today;
        this.getData(this.queryParams);
      });
    },
  },
};
</script>

<style lang="scss">
.v-menu__content {
  z-index: 12 !important;
}
</style>