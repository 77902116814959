<template>
  <div>
    <h1 class="text-center">404 - Not Found</h1>
    <p class="text-center">The page you are trying to reach does not exist.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>