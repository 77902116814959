export const genderChart = ({
  chart: {
    id: 'gender-chart',
    fontFamily: 'Lexend',
    redrawOnParentResize: false,
  },
  colors: ['#2196F3', '#009688', '#8BC34A', '#FFC107', '#FF7043', '#EC407A', '#AB47BC', '#3F51B5'],
  dataLabels: {
    style: {
      fontSize: '1.3rem',
      fontWeight: '400',
    },
    dropShadow: {
      enabled: true,
      top: 0,
      left: 0,
      blur: 2,
      opacity: 0.8,
    },
  },
  legend: {
    fontSize: '16px',
    position: 'left',
    width: 200,
    markers: {
      width: 16,
      height: 16,
      offsetX: -4,
      offsetY: 2,
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
    },
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        chart: {
          height: '300%',
        },
        dataLabels: {
          style: {
            fontSize: '0.9rem',
          },
        },
        legend: {
          fontSize: '14px',
          position: 'bottom',
          width: '100%',
          markers: {
            offsetY: 0,
          },
        },
      },
    },
  ],
  title: {
    text: 'Gender',
    align: 'center',
  },
  tooltip: {
    followCursor: false,
    style: {
      fontSize: '16px',
    },
    y: {
      formatter: (value) => {
        return `${value} Clients`;
      },
      title: {
        formatter: (seriesName) => {
          return `${seriesName}<br>`;
        },
      },
    },
  },
});