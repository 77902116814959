// Data store
import { getDataFromAPI } from '@/services/api-service.js';

const state = {
  data: {
    total: 0,
    ageCategories: [],
    ageSeries: [],
    genderCategories: [],
    genderSeries: [],
    raceCategories: [],
    raceSeries: [],
  },
};

const getters = {
  data: ({ data }) => data,
};

const mutations = {
  SET_DATA(state, apiData) {
    state.data = {
      total: apiData.total,
      ageCategories: apiData.ages.map(age => age.name),
      ageSeries: apiData.ages.map(age => age.count),
      genderCategories: apiData.genders.map(gender => gender.name),
      genderSeries: apiData.genders.map(gender => gender.count),
      raceCategories: apiData.races.map(race => race.name),
      raceSeries: apiData.races.map(race => race.count),
    };
  },
};

const actions = {
  async getData({ commit }, queryParams) {
    getDataFromAPI(queryParams).then(response => {
      commit('SET_DATA', response.data);
    }).catch(error => {
      console.error(error);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};