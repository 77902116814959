<template>
  <div class="home">
    <v-container class="col-xl-6 col-sm-11 py-10">
      <v-row align="center" justify="center">
        <v-col>
          <h1 class="text-center">Welcome to the YouthCenter Dashboard</h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>