import Vue from 'vue';
import Vuex from 'vuex';
import data from '@/store/data';
import organizations from '@/store/organizations';
import user from '@/store/user';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    data,
    organizations,
    user,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});