// Organizations store
import { getOrganizationsFromAPI } from '@/services/api-service.js';

const state = {
  organizations: []
};

const getters = {
  organizations: ({ organizations }) => organizations
};

const mutations = {
  SET_ORGANIZATIONS(state, val) {
    state.organizations = val;
  }
};

const actions = {
  async getOrganizations({ commit }) {
    getOrganizationsFromAPI().then(response => {
      commit('SET_ORGANIZATIONS', response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};