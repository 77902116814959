import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import BizStreamIcon from '@/assets/BizStreamIcon.vue';
import YouthCenterIcon from '@/assets/YouthCenterIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    values: {
      bizstream: {
        component: BizStreamIcon
      },
      youthcenter: {
        component: YouthCenterIcon
      }
    }
  }
});