import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  signOut
} from 'firebase/auth';
import router from '@/router';

const provider = new GoogleAuthProvider();

const state = {
  userProfile: {},
  loggedIn: false,
};

const getters = {
  userProfile: ({ userProfile }) => userProfile,
  loggedIn: ({ loggedIn }) => loggedIn,
};

const mutations = {
  USER_DETAILS(state, userData) {
    state.userProfile = {
      displayName: userData.displayName,
      email: userData.email,
      photoURL: userData.photoURL,
    };
    state.loggedIn = true;
  },
  LOGOUT(state) {
    state.userProfile = {};
    state.loggedIn = false;
  },
};

const actions = {
  async login(store) {
    if (store.state.loggedIn) return;
    const auth = getAuth();
    try {
      await signInWithPopup(auth, provider);
      router.push('dashboard');
    } catch (error) {
      console.error(error);
    }
  },
  async loginMobile(store) {
    if (store.state.loggedIn) return;
    const auth = getAuth();
    try {
      await signInWithRedirect(auth, provider);
      await getRedirectResult(auth);
      router.push('dashboard');
    } catch (error) {
      console.error(error);
    }
  },
  async logout() {
    const auth = getAuth();
    try {
      await signOut(auth);
      router.push('/');
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};