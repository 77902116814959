<template>
  <div class="app-bar">
    <v-app-bar
      color="blue-grey darken-4"
      height="72"
      app
      clipped-left
      dark
      flat
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      >
        <v-icon>mdi-filter-variant</v-icon>
      </v-app-bar-nav-icon>
      <router-link :to="homeLink">
        <v-row>
          <v-img
            :src="require('@/assets/youthcenter-logo-white.svg')"
            alt="YouthCenter Logo"
            max-height="50"
            max-width="200"
            class="ml-3"
            contain
          ></v-img>
        </v-row>
      </router-link>
      <v-spacer></v-spacer>
      <v-menu
        v-if="loggedIn"
        min-width="160px"
        transition="slide-y-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab small>
            <v-avatar size="36">
              <img :src="userProfile.photoURL" :alt="userProfile.displayName" />
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="pa-3 justify-center">
            <div class="mx-auto text-center">
              <p class="ma-2 mt-1 font-weight-bold">
                {{ userProfile.displayName }}
              </p>
              <p class="ma-2 sm-text">
                {{ userProfile.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="logout" text>Logout</v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
      <div v-else>
        <v-btn @click="login" class="hidden-sm-and-down" rounded outlined>
          Login
        </v-btn>
        <v-btn @click="loginMobile" class="hidden-md-and-up" icon small>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      v-if="isDashboard"
      color="blue-grey darken-3"
      width="300"
      app
      clipped
      dark
    >
      <filter-drawer></filter-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import FilterDrawer from '@/components/FilterDrawer.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppBar',
  components: {
    FilterDrawer,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
      loggedIn: 'loggedIn',
    }),
    homeLink() {
      if (this.loggedIn) {
        return '/dashboard';
      } else {
        return '/';
      }
    },
    isDashboard() {
      return this.$route.name === 'Dashboard';
    },
  },
  methods: {
    ...mapActions('user', {
      login: 'login',
      loginMobile: 'loginMobile',
      logout: 'logout',
    }),
  },
};
</script>

<style lang="scss">
.app-bar {
  z-index: 12;
}
</style>