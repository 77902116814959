<template>
  <div class="dashboard-view">
    <v-row>
      <v-col cols="12" md="6" class="d-flex flex-shrink-1">
        <v-card class="pa-6 flex rounded-xl">
          <apexchart
            type="pie"
            :width="chartWidth"
            :options="raceOptions"
            :series="clientsData.raceSeries"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-shrink-1">
        <v-card class="pa-6 flex rounded-xl">
          <apexchart
            type="pie"
            :width="chartWidth"
            :options="genderOptions"
            :series="clientsData.genderSeries"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-shrink-1">
        <v-card class="pa-6 pl-4 flex rounded-xl">
          <apexchart
            type="bar"
            :width="chartWidth"
            :options="ageOptions"
            :series="ageSeries"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-shrink-1">
        <v-card
          class="pa-6 flex d-flex align-center rounded-xl"
          color="blue-grey darken-3"
          dark
        >
          <v-row class="ma-0 py-6 align-center justify-center">
            <v-col cols="12" class="pa-0 d-flex justify-center">
              <span class="lg-number">{{ clientsData.total }}</span>
            </v-col>
            <v-col cols="12" class="pa-0 d-flex justify-center">
              <span class="lg-title text-center">Total Clients</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ageChart, genderChart, raceChart } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardView',
  data: () => ({
    chartWidth: 0,
  }),
  computed: {
    ...mapGetters('data', {
      clientsData: 'data',
    }),
    raceOptions() {
      return {
        labels: this.clientsData.raceCategories,
        chart: raceChart.chart,
        colors: raceChart.colors,
        dataLabels: raceChart.dataLabels,
        legend: raceChart.legend,
        plotOptions: raceChart.plotOptions,
        responsive: raceChart.responsive,
        title: raceChart.title,
        tooltip: raceChart.tooltip,
      };
    },
    genderOptions() {
      return {
        labels: this.clientsData.genderCategories,
        chart: genderChart.chart,
        colors: genderChart.colors,
        dataLabels: genderChart.dataLabels,
        legend: genderChart.legend,
        plotOptions: genderChart.plotOptions,
        responsive: genderChart.responsive,
        title: genderChart.title,
        tooltip: genderChart.tooltip,
      };
    },
    ageOptions() {
      return {
        xaxis: {
          categories: this.clientsData.ageCategories,
          labels: {
            style: {
              fontSize: '1rem',
              fontWeight: '700',
            },
          },
        },
        chart: ageChart.chart,
        colors: ageChart.colors,
        dataLabels: ageChart.dataLabels,
        responsive: ageChart.responsive,
        title: ageChart.title,
        tooltip: ageChart.tooltip,
        yaxis: ageChart.yaxis,
      };
    },
    ageSeries() {
      return [
        {
          name: 'Clients',
          data: this.clientsData.ageSeries,
        },
      ];
    },
    totalClients() {
      return this.clientsData.total;
    },
  },
  mounted() {
    setTimeout(() => {
      this.chartWidth = '100%';
    }, 1);
  },
};
</script>

<style lang="scss">
.lg-title {
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  line-height: 1.8rem;
  text-transform: uppercase;
}

.lg-number {
  font-size: 5rem;
  font-weight: 700;
  line-height: 6rem;
}

div.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 15px 30px 0 #06121830 !important;
}

.apexcharts-title-text {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.apexcharts-legend {
  justify-content: center !important;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0 !important;
}

.apexcharts-legend-marker {
  float: left;
}

.apexcharts-tooltip-text-y-label,
.apexcharts-tooltip-text-y-value {
  display: block !important;
  margin-left: 0 !important;
  text-align: center;
}

@media (max-width: 600px) {
  .apexcharts-legend {
    display: grid !important;
    padding: 0 !important;
  }
}

@media (min-width: 600px) {
  .apexcharts-legend-text {
    display: block;
    padding-left: 20px;
  }
}
</style>