export const ageChart = ({
  chart: {
    id: 'age-chart',
    fontFamily: 'Lexend',
  },
  colors: ['#2196F3'],
  dataLabels: {
    style: {
      fontSize: '1rem',
    },
    dropShadow: {
      enabled: true,
      top: 0,
      left: 0,
      blur: 2,
      opacity: 0.8,
    },
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        chart: {
          height: '300%',
        },
        dataLabels: {
          offsetY: 7,
          style: {
            fontSize: '0.8rem',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: '0.6rem',
              fontWeight: '400',
            },
          },
        },
        yaxis: {
          labels: {
            offsetY: 3,
            style: {
              fontSize: '0.8rem',
              fontWeight: '700',
            },
          },
        },
      },
    },
  ],
  title: {
    text: 'Age',
    align: 'center',
  },
  tooltip: {
    style: {
      fontSize: '16px',
    },
    x: {
      formatter: (value) => {
        return `Age ${value}`;
      }
    },
    y: {
      formatter: (value) => {
        return `${value} Clients`;
      },
      title: {
        formatter: () => {
          return '';
        },
      },
    },
    marker: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '0.8rem',
      },
    },
  },
});