import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/apexcharts';
import '@/plugins/firebase';
import '@/plugins/auth';
import '@babel/polyfill';
import Vuebar from 'vuebar';

Vue.config.productionTip = false;
Vue.use(Vuebar);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');