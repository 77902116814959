import { getAuth, onAuthStateChanged } from 'firebase/auth';
import store from '@/store';

const auth = getAuth();

onAuthStateChanged(auth, async (user) => {
  if (user) {
    store.commit('user/USER_DETAILS', user);
  } else {
    store.commit('user/LOGOUT');
    sessionStorage.clear();
  }
});