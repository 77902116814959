<template>
  <v-app>
    <app-bar></app-bar>
    <div v-bar>
      <v-main>
        <v-container class="col-11 my-6" fluid>
          <v-slide-x-transition hide-on-leave>
            <router-view></router-view>
          </v-slide-x-transition>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue';
import { mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    AppBar,
  },
  computed: {
    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },
  methods: {
    ...mapActions('data', {
      getData: 'getData',
    }),
    ...mapActions('organizations', {
      getOrganizations: 'getOrganizations',
    }),
  },
  mounted() {
    this.getOrganizations();
    this.getData('?date=' + this.today);
  },
};
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

#app {
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-main {
  background: #eceff1;
  padding-top: 0 !important;
}

.v-main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.vb {
  width: 100vw;
  height: calc(100vh - 72px);
  flex-direction: column;
  overflow: scroll;
  margin-top: 72px;
  padding-top: 0 !important;
  > .vb-dragger {
    z-index: 5;
    width: 14px;
    right: 0;
    > .vb-dragger-styler {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: rotate3d(0, 0, 0, 0);
      transform: rotate3d(0, 0, 0, 0);
      -webkit-transition: background-color 300ms ease-out, margin 300ms ease-out,
        height 300ms ease-out;
      transition: background-color 300ms ease-out, margin 300ms ease-out,
        height 300ms ease-out;
      background-color: #26323800;
      margin: 4px 4px 4px 0;
      border-radius: 20px;
      height: calc(100% - 8px);
      display: block;
    }
  }
}
.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: #2632384d;
}
.vb > .vb-dragger:hover > .vb-dragger-styler,
.vb.vb-dragging > .vb-dragger > .vb-dragger-styler,
.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: #26323880;
}
</style>
