<template>
  <div class="dashboard">
    <dashboard-view></dashboard-view>
  </div>
</template>

<script>
import DashboardView from '@/components/DashboardView.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardView,
  },
};
</script>